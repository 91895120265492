﻿@import 'styles/_app.scss';

.type-approval-editor {
    @extend .__section;
}

.type-approval-editor__name {
    @extend .__section-name;
}

.type-approval-editor__card {
    @extend .__card;
    width: 450px;
    padding: 1.25rem;
    margin-top: 20px;
    height: 100%;
}

.type-approval-editor-card__buttons {
    @extend .__button-container;
    justify-content: right;
}

.type-approval-editor__approval-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 20px;
    margin-top: 10px;
    padding: 10px 0;
    border-bottom: 3px solid $lightGrey;
}

.type-additional-user-card {
    @extend .__card;
    @extend .__card--readonly;
    padding: 1.25rem;
    min-width: 240px;
}

.type-additional-user-card__icons {
    @extend .__icon;
    float: right;
}

.type-additional-user-card__inactive{
    @extend .type-additional-user-card;
    color: $lightGrey;

    .type-additional-user-card__icons{
        cursor: default;
        pointer-events: none;
    }
}

