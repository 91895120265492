@import '../../styles/_smcColors.scss';

.copyright {
    color: $lightBlue;
    font-size: 0.75rem;
    font-weight: initial;
    vertical-align: middle;
}

.login {
    background-color: $darkBlue;
    background-position: center;
    background-size: cover;
    height: inherit;

    &:before {
        content: "";
        background: url("./assets/building1400x690.png");
        background-position: center;
        background-size: cover;
        height: inherit;
        opacity: 0.1;
        display: block;
    }
}

.login__logo {
    height: 65vh;
    height: calc(var(--vh, 1vh) * 65);
}

.login__button {
    height: 25vh;
    height: calc(var(--vh, 1vh) * 25);

    .btn-primary:focus {
        background-color: $smcBlueContrast;
    }
}

.login__content {
    text-align: center;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.login__inactive-user {
    padding-top: 10px;
    color: #ff0000;
}