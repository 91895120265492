.new-dependent-field-container {
    padding-top: 10px;
    text-align: center;
}

.modal-content {
    width: unset;
}

.action-modal__body {
    padding-top: unset;
    padding-bottom: unset;
}

.dependent-field-panel-container > .template-field-container {
    width: 375px;
}

.wrapper {
    position: relative;
}

.frame {
    width: 19px;
    position: absolute;
    border-radius: 5px;
    border-top: 1px solid #a6a6a6;
    border-left: 1px solid #a6a6a6;
    border-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    left: 6px;

    &--optionA {
        @extend .frame;
        top: 10px;

        &__1 {
            @extend .frame--optionA;
            height: 52px;
        }

        &__2 {
            @extend .frame--optionA;
            height: 187px;
        }

        &__3 {
            @extend .frame--optionA;
            height: 322px;
        }

        &__4 {
            @extend .frame--optionA;
            height: 457px;
        }

        &__5 {
            @extend .frame--optionA;
            height: 592px;
        }

        &__6 {
            @extend .frame--optionA;
            height: 727px;
        }

        &__7 {
            @extend .frame--optionA;
            height: 862px;
        }

        &__8 {
            @extend .frame--optionA;
            height: 997px;
        }
    }

    &--optionB {
        @extend .frame;
        top: 31px;

        &__1 {
            @extend .frame--optionB;
            height: 31px;
        }

        &__2 {
            @extend .frame--optionB;
            height: 166px;
        }

        &__3 {
            @extend .frame--optionB;
            height: 301px;
        }

        &__4 {
            @extend .frame--optionB;
            height: 436px;
        }

        &__5 {
            @extend .frame--optionB;
            height: 571px;
        }

        &__6 {
            @extend .frame--optionB;
            height: 706px;
        }

        &__7 {
            @extend .frame--optionB;
            height: 841px;
        }

        &__8 {
            @extend .frame--optionB;
            height: 976px;
        }
    }
}