@import "styles/_app.scss";
@import "styles/_smcColors.scss";
$marginSpacing: 7px;

.chip__container {
    border-radius: 25px;
    min-width: 40px;
    border-color: $gray50;
    border: 1px solid $gray50;
    display: inline-grid;
    grid-template-columns: auto auto auto;
    padding: 4px 8px 4px;
    font-weight: normal;
    font-size: 0.8215rem;
}

.chip__container.clickable {
    transition: 0.2s;
    &:hover {
        background-color: $gray20;
        cursor: pointer;
    }
}

.close-icon {
    margin-left: $marginSpacing;
    &:hover {
        color: $gray50;
        cursor: pointer;
    }
}

.center-text {
    justify-self: center;
}

.close-icon__container {
    justify-self: end;
}

.left-icon {
    margin-right: $marginSpacing
}