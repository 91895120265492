@import "styles/_smcColors.scss";

.approval-template {
  border: {
    color: $gray10;
    radius: 3px;
    width: 1px;
  }
  margin-bottom: 1.25rem;
  padding: 10px;
  box-shadow: 0 2px 4px $gray20;
}

.approval-template__header {
  border-bottom: 1px solid $gray10;
  border-radius: 3px;
  padding: 0.75rem 0;
}

.new-approval-level__button {
  margin-top: 0.75rem;
}
