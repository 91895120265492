@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.approver-list__approvers {
  @extend .__scrollable-sidebar__content;
  padding: 0.625rem;
}

.admin-review {
  text-align: center;
}

.admin-review__header {
  padding-top: 20px;
  padding-bottom: 20px;
  color: $darkBlue;
  font-weight: initial;
}

.admin-review__buttons {
  display: flex;
}

.admin-review__buttons--cell {
  flex: 1;
}

.proxy-toggle__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;

  .proxy-toggle__label {
    @extend .__default-font;
    color: $smcBlue;

    &.disabled {
      color: $gray50;
    }
  }
}

.send-to-japan-modal__header {
}

.send-to-japan-modal__sub-header {
  margin-bottom: 20px;
}

.send-to-japan-modal__division-select {
  margin-bottom: 10px;
  width: 380px;
}

.send-to-japan-modal__selected-emails {
  min-height: 180px;
  max-height: 310px;
  overflow-y: auto;
}

.send-to-japan-modal__selected-emails-container {
  border-radius: 25px;
  border: 1px solid $darkGrey;
  padding: 10px;
  width: 380px;
  height: 50px;
  margin-bottom: 8px;
}

.send-to-japan-modal__selected-emails-email-icon {
  margin-left: 5px;
  margin-top: 5px;
}

.send-to-japan-modal__selected-emails-email {
  margin-left: 10px;
  margin-top: 5px;
}

.send-to-japan-modal__selected-emails-remove-icon {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.send-to-japan-modal__add-email-container {
  margin-top: 25px;
  margin-bottom: 25px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.send-to-japan-modal__add-email-field-group {
  grid-column-start: 1;
  grid-column-end: 3;
}

.send-to-japan-modal__add-email-label {
  font-weight: bold;
}

.send-to-japan-modal__add-email-input {
  width: 100%;
  height: 40px;
}

.send-to-japan-modal__add-email-button {
  margin-left: 10px;
  grid-column-start: 3;
  margin-top: 32px;
}

.send-to-japan-modal__error {
  position: absolute;
  left: 17.5em;
  bottom: 0.5em;
}
