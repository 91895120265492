@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.history-log-card {
    @extend .__card;

    .history-log-card__wrapper {
        flex-grow: 1;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        padding: 0.75rem;
        font-weight: initial;
        row-gap: 0.5rem;
        align-items: center;
        color: $mutedText;
    }

    .history-log-card__date {
        white-space: nowrap;
        font-size: 12px;
    }

    .history-log-card__text {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}
