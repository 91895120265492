﻿@import 'styles/_variables.scss';

.__configuration-page-header {
    font: {
        size: 1rem;
    }

    margin: {
        bottom: 10px;
        top: 25px;
    }

    display: flex;
    align-items: center;
}

.__configuration__content {
    padding-left: 15px;
    padding-right: 20px;
}

.__configuration-page-header__description {
    font: {
        family: $baseFontFamily;
        size: 1.25em;
        weight: bold;
    }

    margin: {
        left: 10px;
    }

    flex-grow: 1;
}

.__type-card {
    @extend .__card-box-shadow;
    width: 20rem;
    min-height: 5.25rem;
    height: 100%;

    padding: {
        top: 1.75rem;
        bottom: 1.75rem;
        left: 0.75rem;
        right: 0.625rem;
    }

    margin-top: 1.25rem;
    margin-right: .25rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;

    background: {
        color: $white;
        position: 0%;
        repeat: no-repeat;
        origin: padding-box;
        clip: padding-box;
    }

    &.new-type-form {
        height: auto;

        &:hover {
            background-color: inherit;
        }
    }

    &:hover {
        background-color: $lightBlue;

        border: {
            color: $smcBlueContrast;
        }

        cursor: pointer;
    }

    span {
        text-align: left;

        font: {
            style: normal;
            variant: normal;
            weight: bold;
            size: 14px;
            family: $baseFontFamily;
        }

        line-height: 1.1875rem;
        letter-spacing: -0.34px;
        color: #000;
    }
}
