@import 'styles/_app.scss';

.approver-tab {
    &:hover {
        @extend .__card--hover;
    }

    @extend .__card-box-shadow;
    border-color: #D8D8D8;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    padding: 1.25rem;
    width: 300px;
    height: fit-content;
    margin: 0.5rem;

    display: flex;
}

.approver-tab__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.approver-tab-form__description {
    font-weight: normal;
}

.approver-tab__icons {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}
