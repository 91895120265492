@import 'styles/_app.scss';
@import 'styles/_smcColors.scss';

.view-ringi__sidebar {
    background-color: $lightGrey;

    .tab-content {
        overflow: auto;
        flex-grow: 1;
    }
}

.view-ringi__sidebar--delimiter {
    border-top: solid 3px #828282;
    border-left: solid 3px #828282;
}

.view-ringi-sidebar__tab_header {
    display: flex;
    flex-direction: row;
}

.view-ringi_sidebar__back-button-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $lightGrey;
    border-right: 1px solid $lightGrey;
    max-height: 60px;
}
.view-ringi__back-button {
    @extend .__back-arrow;
    
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
}

.view-ringi-sidebar__tabs {
    flex-grow: 1;

    .view-ringi-sidebar__tab {
        flex-grow: 1;

        a {
            height: 3.75rem;
            border-radius: 0;
            background-color: $darkBlue;
            color: $white;
            font-weight: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            @media screen and (min-width: 992px) {
                padding-left: 1.0rem;
                padding-right: 1.0rem;
                font-size: 15px;
            }

            &.active {
                background-color: $lightGrey;
                border: none;
                color: $darkBlue;
                border-top: 5px solid $smcBlue;
            }
        }
    }

    .view-ringi-sidebar__ringi-details-tab {
        a {
            &.active {
                background-color: $white;
                color: $darkerBlue;
            }
        }
    }

    .view-ringi-sidebar__ringi-details-wide-tab {
        a {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.view-ringi-sidebar__tab-content.tab-pane.active {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.view-ringi-sidebar__ringi-details {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: white;

    &.tab-pane.active {
        height: auto;
    }
}

.view-ringi-sidebar__more-tabs-visible {
    display: block;
}

.view-ringi-sidebar__more-tabs-invisible {
    display: none;
}
