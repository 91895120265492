@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.validation-text-area {
  @extend .__input-sensitive-group;
}

.validation-text-area__label {
  @extend .__input-sensitive-group__label;
  display: inline-block;
  padding-right: 10px;
  @extend .__default-font-size-small;
}

.validation-text-area__text-area {
  @extend .__text-area;
  width: 100%;
  min-height: 3rem;
}

.validation-text-area--error {
  position: relative;

  .validation-text-area__text-area,
  .validation-text-area__text-area:focus {
    @extend .__validation-field-error;
  }

  .validation-text-area__label {
    color: $errorRed;
  }

  textarea {
    padding-right: 25px;
  }

  .validation-text-area__icon {
    color: $errorRed;
    position: absolute;
    right: 44px;
    top: 36px;
  }

  .validation-error-message {
    @extend .__default-font-light;
    @extend .__default-font-size-small;
    @extend .__validation-tool-tip;
  }
}
