@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.comment-card {
  @extend .__card;

  .comment-card__info {
    flex-grow: 1;
    display: grid;
    grid-template-columns: min-content auto auto;
    grid-template-rows: auto;
    padding: 0.75rem;
    font-weight: initial;
    row-gap: 0.5rem;
    align-items: center;
  }

  .comment-card__name {
    background-color: $lightGrey;
    color: $darkGrey;
    padding: 5px 15px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .comment-card__date {
    justify-self: left;
    white-space: nowrap;
    font-size: 12px;
    color: $mutedText;
  }

  .comment-card__icon {
    justify-self: end;
    cursor: pointer;
    color: $darkGrey;
    white-space: nowrap;
    grid-column-start: 3;
  }

  .comment-card__denied-label {
    @extend .__denied-status-text;
    font-size: 12px;
    justify-self: flex-end;
    width: max-content;
    grid-column-start: 3;
  }

  .comment-card__cancelled-label {
    @extend .__cancelled-status-text;
    font-size: 12px;
    justify-self: flex-end;
    width: max-content;
    grid-column-start: 3;
  }

  .comment-card__icon > svg {
    margin: 5px;
  }

  .comment-card__text {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .comment-card__reply-needed {
    @extend .comment-card__text;
    color: $errorRed;
    font-weight: bold;
  }

  .comment-card__text-form {
    text-align: end;
  }

  .comment-card__pause-checkbox {
    input {
      margin-right: 10px;
    }
  }

  .reply-buttons-container {
    height: min-content;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .reply-buttons-attach-save {
    display: flex;
    align-items: flex-end;
  }

  .comment-card__file-input-wrapper {
    display: inline-flex;
    align-items: center;

    .btn {
      margin-bottom: 0;
      margin-right: 0px;
    }

    margin-bottom: 0px;
  }

  .reply-buttons-attach {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;

    .attached-files__file-input-wrapper {
      display: inline;
      align-items: normal;
      margin-bottom: 0;

      label {
        margin-bottom: 0;
      }
    }
  }

  .save-button {
    color: $darkGrey;
    margin-left: 10px;
  }

  .cancel-button {
    color: $darkGrey;
    float: left;
  }

  .comment-card__attachments {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .attachment-remove-container {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  .comment-card__conditional-approval-text {
    font-weight: bold;
    color: $positiveGreen;
  }
}
