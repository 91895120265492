@import 'pages/Configuration/styles/_configuration.scss';
@import 'styles/_app.scss';

.type-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.type-list__add-type {
    @extend .__type-card;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
}

.type-list__add-type-button {
    @extend .__primary-button;

    svg {
        margin: {
            right: 0.5rem;
        }
    }
}

.type-list__add-type--out-of-focus {
    @extend .__out-of-focus;
}

.type-list__add-type-editor {
    @extend .__type-card;
}