@import "styles/_smcColors.scss";

.validation-attachment {
  font-weight: normal;

  border: {
    width: 1px;
    style: solid;
    radius: 20px;
    color: $smcBlueContrast;
  }

  font-size: 0.9rem;
  padding: 10px 15px;

  margin: {
    right: 0.5rem;
  }

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
}

.validation-attachment-wrapper {
  display: flex;
  align-items: center;

  margin: {
    bottom: 0.5rem;
  }
}

.validation-attachment__remove-file-icon {
  display: flex;

  margin: {
    left: auto;
    right: 17px;
  }

  cursor: pointer;
}

.validation-attachment--error {
  border-color: $errorRed;
  color: $errorRed;
  outline-color: $errorRed;
}

.validation-attachment__file-name {
  margin: {
    right: 20px;
    left: 10px;
  }
  overflow-wrap: break-word;
  max-width: 21.5vw;
}

.validation-attachment__download {
  margin-left: 5px;
}

.validation-attachment-download--normal-font {
  font-weight: normal !important;
}

.validation-attachment__icon {
  color: $errorRed;
  position: relative;
  right: 7px;
}
