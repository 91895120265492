@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.ringi-field {
  margin: 0.5rem;
  padding: 0.5rem;
  border: {
    color: #d8d8d8;
    radius: 3px;
    style: solid;
    width: 0.5px;
  }

  box-shadow: 0px 2px 4px rgba($boxShadowColor, 0.16);
}

.ringi-field__name {
  font-weight: normal;
  color: $darkerBlue;
  padding-right: 10px;
}

.ringi-field__value {
  width: 90%;
  margin-left: 0.5rem;
  font-weight: initial;
  color: $gray50;
  min-height: 1.5rem;
  min-width: 100px;

  &.ringi-field__value--approved {
    @extend .__approved-status-text;
  }

  &.ringi-field__value--denied {
    @extend .__denied-status-text;
  }

  &.ringi-field__value--pending {
    @extend .__pending-status-text;
  }

  &.ringi-field__value--cancelled {
    @extend .__cancelled-status-text;
  }

  &.ringi-field__value--paused {
    @extend .__paused-status-text;
  }

  &.ringi-field__value--negative {
    color: $smcBlue;
    font-weight: bold;
  }
}

.ringi-field__date-range {
  margin-top: 0.5rem;
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ringi-field-date-range__date {
  display: inline-block;
}

.ringi-field-date-range__arrow-icon {
  margin: {
    left: 2rem;
    right: 2rem;
  }
}

.value-and-icon {
  display: flex;
}

.edit-button {
  cursor: pointer;
}
