@import "styles/_app.scss";

.btn-primary.division-list__add-new-division {
  @extend .__primary-button-small;
}

.division-list__section {
  padding: {
    left: 1.25rem;
    right: 1.25rem;
    top: 1.5rem;
  }
}

.division-list__content {
  @extend .__scrollable-sidebar__content;
}

.division-list__no-divisions-message {
  @extend .__empty-text;
  margin-top: 0.5rem;
}

.division-tab-container--disabled {
  @extend .__card--disabled;
}
