@import 'styles/_smcColors.scss';

.application-settings-header {
    margin: {
        right: 1.5rem;
    }

    .ringi-page-content-header__title {
        flex: 1 1;
    }
}

.application-setting-field {
    padding: 10px;
}

.application-setting-help-text {
    font-weight: normal;
    font-size: 14px;
    color: $mutedText;
}

.application-settings-footer {
    display: block;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.application-settings-footer__cancel {
    padding-right: 20px !important;
}

.application-settings__ringi-field {
    .ringi-field{
        margin: 0.5rem;
        padding: 0.5rem;
        border-style: none;
        box-shadow: none;
    }
}

.edit-settings-form__button {
    margin-left: 20px;

    svg {
        margin: {
            right: 0.5rem;
        }
    }
}