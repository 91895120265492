@import 'styles/_app.scss';
@import 'styles/_smcColors.scss';

.approval-template-tab-container{
    @extend .__card;
    position: relative;

    .nav-link {
        color: black;
        cursor: pointer;

        &.active {
            @extend .__card--active;
        }
    }

    span + svg {
        margin: {
            left: 0.625rem;
        }
    }

    &:hover {
        @extend .__card--hover;
    }
}
.approval-template-tab {
    overflow: hidden;

    padding: {
        right: 50px;
    }

    text-overflow: ellipsis;
    white-space: nowrap;
}

.approval-template-tab-warning__container{
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-50%);
}

.approval-template-tab-warning_tooltip{
    .tooltip-inner {
        background-color: $warningYellow;
        color: black;
    }
}
.approval-template-tab-warning{
    color: $warningYellow;
}

.approval-template-tab-menu{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
}

.approval-template-tab--disabled {
    @extend .__card--disabled;
}