@import "styles/_app.scss";

.pilltext__container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.pilltext__body--positive {
  color: white;
  background-color: $positiveGreen;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
  min-width: 40px;
}

.pilltext__body--negative {
  @extend .pilltext__body--positive;
  background-color: $errorRed;
}
