@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.view-ringis {
  padding: 0px;
  margin: 0px;
}

.view-ringis__list-selector {
  @extend .__select;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
}

.view-ringis__search-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-left: auto;
  padding-top: 1px;
  width: 455px;
}

.view-ringis__search-functionality {
  @extend .__default-font-size-small;
  display: flex;
  border: 1px solid $gray50;
  border-radius: 5px;
  background-color: $white;
  width: 100%;
  justify-content: space-between;

  .validation-select__select,
  button,
  input {
    border: none;
    box-shadow: none;
  }
  .validation-select__icon {
    right: 44px;
    bottom: 6px;
  }
  .validation-select__select,
  button {
    border-radius: 5px;
  }
  .validation-select__select {
    width: max-content;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .validation-input {
    width: inherit;
    input:focus-within {
      box-shadow: none;
      outline: none;
    }
  }

  .view-ringis__search-button {
    background-color: $white;
    color: rgb(26, 26, 26);
    border: none;
    padding: 0 20px 0 10px;
    cursor: pointer;
  }
}

.view-ringis__ringi-number-format {
  @extend .__default-font-light;
  font-size: 0.5em;
  margin-bottom: -1.5em;
  position: relative;
  left: 15em;
}

.view-ringis__buttons {
  padding-left: 10px;
  display: flex;
  justify-content: flex-end;
  a {
    text-decoration: none;
  }
}
.view-ringis-buttons__filters {
  margin-right: 10px;
  padding: 1px 10px 1px 10px !important;
  min-width: 55px;
}

.view-ringis-buttons__columns {
  @extend .view-ringis-buttons__filters;
  background-color: $darkGrey !important;
  border-color: $darkGrey !important;
}

.default-view-link__container {
  margin-left: 20px !important;
}

.default-view-link__outer-container-mobile {
  width: 100%;
  text-align: right;
}

.default-view-link__container-mobile {
  text-decoration: underline;
  margin-bottom: 10px;
}

.btn-add-ringi.btn-primary {
  @extend .__primary-button;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-items: center;
  min-width: 55px;

  padding: {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  margin-left: auto;
}

.btn-add-ringi__text {
  margin-left: 0.625rem;
}

.view-ringis-header__container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
}

.view-ringis-header__container-mobile {
  width: 100vw;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 35px;
}

.view-ringis__list-selector-container-mobile {
  grid-column: 1 / 4;
  grid-row: 1;
  margin-right: 10px;
}

.view-ringis__search-container-mobile {
  width: 100%;
  height: fit-content;
  grid-column: 1 / 5;
  grid-row: 2;
}

.view-ringis__buttons-mobile {
  padding-left: 10px;
  display: flex;
  justify-content: flex-end;
  grid-column: 4 / 5;
  grid-row: 1;

  a {
    text-decoration: none;
  }
}

.view-ringis__list-container-mobile {
  overflow: auto;
  display: block;
  padding-top: 10px;
  height: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 110px;
}

.view-ringis__list-selector-mobile {
  @extend .__select;
  width: 100%;
  height: 100%;
  font-size: 15px;
  cursor: pointer;
  background-color: $darkBlue;
  color: $white;
  border-color: $smcBlue;
}

.view-ringis__page-header-mobile {
  background-color: $darkBlue;
  display: inline-flex;
  padding-top: 0;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
}

//Common Flyout styling
.flyout__overlay {
  @extend .__page-overlay;
  z-index: 1000;
  cursor: auto;
}

.flyout {
  z-index: 1001;
  visibility: hidden;
  width: 0;
  min-height: 100%;
  background-color: $darkBlue;
  color: $white;
  padding: 20px;
  position: absolute;
  right: 20;
  top: 0;
  transition: width 0.5s;
}

.flyout--active {
  visibility: visible;
  right: 0;
  width: 400px;
}

.flyout--mobile {
  width: 100vw;
}

.flyout__header {
  display: flex;
  justify-content: space-between;

  label {
    padding-top: 3px;
  }
}

.filter-flyout__header {
  display: flex;
  label {
    padding: 3px 0 0 10px;
  }
}

.flyout__rounded-button {
  width: 30px;
  height: 30px;
  padding-top: 3px;
  text-align: center;
  border: 1px solid $white;
  border-radius: 5px;
  cursor: pointer;
}

.flyout__rounded-button--disabled {
  width: 30px;
  height: 30px;
  padding-top: 3px;
  text-align: center;
  color: $gray50;
  border: 1px solid $gray50;
  border-radius: 5px;
  cursor: unset;
}

.flyout__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flyout__buttons-centered {
  text-align: center;
  margin-bottom: 20px;
}

//Filter Flyout section
.filter-flyout-content__container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;

  span {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div {
    display: flex;
    width: 100%;
    column-gap: 20px;
  }

  .filter-flyout-input__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    border: 1px solid $gray50;
    border-radius: 2px;
    font-weight: bold;
  }

  .filter-flyout-input__option-button {
    @extend .filter-flyout-input__button;
    width: 100%;
  }

  .filter-flyout-content__arrow {
    width: min-content;
    align-self: center;
  }

  .filter-flyout-content__selected-field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      align-items: center;
      border: 1px solid $smcBlueContrast;
      height: min-content;
      border-radius: 5px;
      padding: 3px 10px;
    }
    span {
      width: min-content;
      cursor: pointer;
    }
  }
}

.filter-flyout__clear-filters {
  text-align: center;
  width: 100%;
  text-decoration: underline;
  font-weight: 100;
  cursor: pointer;
}

.filter-flyout-content__x-old {
  position: relative;
  height: 0px;
  width: 0px !important;
  bottom: 1.4rem;
  left: 93%;
  color: $errorRed;
  cursor: pointer;
}

.filter-flyout-content__x {
  color: $white;
  cursor: pointer;
}
//End Filter flyout section

//Columns Flyout section
.columns-flyout-content__row {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 6fr 1fr;
  grid-gap: 1px;
}

.columns-flyout-content__x {
  color: $errorRed;
  cursor: pointer;
  justify-self: center;

  svg {
    vertical-align: -0.4em;
  }
}

.removed-columns-flyout-content__row {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1px;
}

.grid-child {
  input {
    width: 100%;
  }
}

.columns-flyout-buttons__add-column-button {
  background-color: unset !important;
  box-shadow: unset !important;
  border-width: 0 !important;
}

.columns-flyout-buttons__reset-columns-button {
  color: #fff !important;
  font-weight: normal !important;
}

.columns-flyout-content__input {
  cursor: default;
}

.removed-columns-flyout-content__input {
  cursor: pointer;
}
//End Filter flyout section
