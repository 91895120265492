@import 'styles/_app.scss';
@import '../FormBuilder.scss';

.type-additional-users{
    width: 100%;
    margin-top: 10px
}

.type-approval-template-editor__name{
    width: 100%;
}

.type-additional-users-card {
    @extend .__card;
    margin: 0;
    min-width: 240px;
    height: min-content;
}

.type-additional-users-card__add{
    @extend .type-additional-users-card;
    padding: 20px;
}

.type-additional-users-card__button{
    @extend .type-additional-users-card;
    padding: 13px;
    flex: 0 1 200px !important;

    & button {
        width: 100%;
    }
}

.type-additional-users-editor{
    display: flex;
}

.type-additional-users-editor__user-list {
    @extend .type-approval-editor__approval-container;
    & > * {
        flex: 0 1 368px;
    }
}

.type-additional-users-editor__user {
    display: flex;
    justify-content: space-between;
}

.type-additional-users-editor__cancel-icon-div {
    text-align: right;
}

.type-additional-users-editor__cancel-icon {
    @extend .__icon;
}

.type-additional-users-editor__buttons {
    @extend .__button-container;
}

.type-additional-users-editor-header {
    @extend .__card__title;
}

.type-additional-users-editor-header__cancel {
    @extend .__card__cancel-button;
}