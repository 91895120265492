@import '../styles/_app.scss';
@import '../styles/_smcColors.scss';

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $smcBlueTrack;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $smcBlue;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $lightBlue;
}

@-moz-document url-prefix() {
    body {
        scrollbar-color: $smcBlue $smcBlueTrack;
    }

    * {
        scrollbar-width: thin;
    }
}

div.container-fluid {
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: {
        left: 0;
        right: 0;
    }
}

.page {
    @extend .__default-font;
}

.btn-primary {
    @extend .__primary-button;

}

.btn-primary-disabled {
    @extend .__disabled-button;
}

.btn-link {
    color: $darkerBlue;
    @extend .__default-font;
    @extend .__default-font-size-small;
    line-height: 1.1875rem;
    padding: 0;
}

.page__header {
    padding: {
        left: 15px;
        right: 15px;
    }
}

.flex {
    display: flex;
}

.page__main {
    overflow: auto;
    height: 100%;
    page-break-after: always;
}

::placeholder {
    @extend .__default-font-light;
}

.text-bold {
    font-weight: bold;
}

@media print {
    *, *::before, *::after {
        overflow: visible !important;
    }
}

@page {
    size: A4;
    margin: auto;
}