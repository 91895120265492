.logo__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.logo__appName {
    letter-spacing: 0.25rem;
    font-weight: initial;
    font-size: 0.8rem;
    color: #fff;
    text-transform: uppercase;
    padding-top: 0.75rem;
}
