@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.ringi-field__value-formatted {
    width: 98%;
    height: 75px;
    border-width: 0;
}

.display-contents-on-print {
    display: none;
}

@media print {
    .ringi-field__value-formatted {
        display: none;
    }

    .display-contents-on-print {
        display: contents;
        white-space: pre-wrap;
    }
}