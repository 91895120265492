@import "styles/_app.scss";

.approval-list__header {
  @extend .__scrollable-sidebar__header;

  .approval-list_add-approval-template-button--small {
    @extend .__primary-button-small;
  }
}

.approval-list-header__controls {
  @extend .__scrollable-sidebar-header__controls;
  justify-content: space-between;
}

.approval-list-header__new-approval-template-buttons {
  @extend .__button-container;
  padding-top: 30px;
}

.approval-list__add-approval-template-section {
  @extend .__scrollable-sidebar-header__add-new-section;
}

.approval-list__new-approval-template-input {
  @extend .__input;
  width: 100%;
}

.approval-list__section {
  padding: {
    left: 1.25rem;
    right: 1.25rem;
  }
}

.approval-list__content {
  @extend .__scrollable-sidebar__content;
}

.approval-list__no-templates-message {
  @extend .__empty-text;
  margin-top: 0.5rem;
}

.approval-list__footer {
  .approval-list_add-approval-template-button--large {
    @extend .__scrollable-sidebar-footer__button;
  }
}
