@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.comment-list__comments {
  @extend .__scrollable-sidebar__content;
  padding: 0.625rem;
}

.comment-list__no-comments-message {
  text-align: center;
  font-weight: normal;
}

.add-comment-bar {
  @extend .__scrollable-sidebar__footer;
  padding: 0.625rem;
  background-image: $smcDarkBlueGradient;
  transition: height 0.5s ease-out;
  overflow: hidden;
}

.add-comment-bar__top-row-container {
  display: flex;

  .add-comment-bar__add-attachment {
    justify-self: end;
    text-align: right;

    .validation-attachment-wrapper {
      justify-content: right;
    }

    .validation-attachment {
      background-color: #fff;
      margin-right: 0;
    }
  }

  .add-comment-bar__pause-checkbox {
    display: flex;
    justify-self: left;
    text-align: left;
    align-items: center;
    color: $white;
    font-weight: normal;
  }

  .add-comment-bar__pause-checkbox-label {
    input {
      margin-right: 10px;
    }
  }
}

.align-space-between {
  justify-content: space-between;
}

.align-end {
  justify-content: end;
}

.add-comment-bar__comment-container {
  padding-top: 1px;
  display: grid;
  grid-template-columns: auto 50px;
  column-gap: 5px;
  align-items: center;

  .add-comment-bar__add-comment {
    justify-self: end;
  }
}

.add-comment-button {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.btn-add-attachment__text {
  margin-left: 0.625rem;
}

.attached-files__file-input-wrapper .btn {
  margin-right: 0;
}
