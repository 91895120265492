@import 'pages/Configuration/styles/_configuration.scss';
@import 'styles/_smcColors.scss';
@import 'styles/_app.scss';

.type-panel__name {
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.1875rem;
    letter-spacing: -0.34px;
}


.type-panel__context-menu {
    cursor: pointer;
}

.type-panel {
    @extend .__type-card;
}

.type-panel--out-of-focus {
    @extend .__out-of-focus;
}

.type-panel--draft {
    background-color: $draftGray;

    .type-panel__name {
        color: $mutedText;
    }

    &.type-panel--out-of-focus {
        background-color: $draftGray;
    }
}

.type-panel--disabled {
    @extend .__card--disabled;

    &.type-panel--out-of-focus:hover {
        background-color: $disabledGray;
    }
}

.type-template-tab-warning__container {
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-50%);
}

.type-template-tab-warning_tooltip {
    .tooltip-inner {
        background-color: $warningYellow;
        color: black;
    }
}

.type-template-tab-warning {
    color: $warningYellow;
}