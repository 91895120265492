@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.title__container {
  display: flex;
}

.icon__label {
  margin: 0 20px 10px 10px;
}

.heading__container {
  display: flex;
  flex-wrap: wrap;
}

.button__container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 0 10px;
}

.back__icon {
  margin-top: 1px;
}

.back-button__link {
  color: $smcBlue !important;

  &:hover {
    color: $lightBlue !important;
  }
}

.cancel-button__flexitem {
  margin-top: -5px;
}

.copy-ringi-button__flexitem {
  margin-left: auto;
  margin-top: -5px;
}

.btn-cancel-ringi {
  @extend .__primary-button;
  background-color: #fff !important;
  color: $smcBlue !important;
  width: 100px;
  padding-left: 5px !important;
  padding-right: 5px !important;

  span {
    font-weight: bold;
  }
}

.btn-copy-ringi {
  @extend .__primary-button;
  background-color: #fff !important;
  color: $smcBlue !important;
  width: 100px;
  padding-left: 5px !important;
  padding-right: 5px !important;

  span {
    font-weight: bold;
  }
}
