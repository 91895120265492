@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.approver-card {
  @extend .__card;
  padding: 1rem;
  position: relative;

  .action-date {
    margin-left: 10px;
  }

  .approver-card__field--light {
    font-size: 12px;
    color: $mutedText;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .approver-card__field--dark {
    font-size: 12px;
    color: $mutedText;
    font-weight: 700;
  }

  .approver-card__field--approved {
    color: $positiveGreen;
  }

  .approver-card__field--denied {
    color: $errorRed;
  }

  .approver-card__proxy-label {
    font-size: 12px;
    color: $mutedText;
    font-style: italic;
    position: absolute;
    right: 5%;
    bottom: 3%;
  }

  .approver_card__button {
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin-left: 0.5rem;
  }

  .approver-card__approve-ringi {
    @extend .__success-button;
  }

  .approver-card__deny-ringi {
    @extend .__danger-button;
  }
}

.approver-card--disabled {
  .approver-card__field {
    color: $mutedText;
  }
}

.approver-card--enabled {
  background-color: $lightGrey;
  border: 1px solid $smcBlueContrast;
}

.approver-card__flex {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.3rem;
  font-weight: initial;
}

.item {
  display: flex; /* Make it flex */
  flex-direction: column; /* Make images and text go above one another */
  justify-content: space-between; /* If the parent is bigger than image + text, add space between them */
  border: 1px solid green;
}

.approver-card__delete {
  cursor: pointer;

  position: absolute;
  top: 4px;
  right: 6px;
}

.headquarter-approval-modal__buttons {
  justify-content: center;
}

.approve-modal__body {
  margin-top: 10px;

  span {
    margin-top: 10px;
    width: 100%;
  }

  label {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .approve-modal-condition-checkbox {
    display: flex;
    align-items: center;

    .validation-input {
      margin-right: 10px;
    }
    input {
      height: min-content;
    }
  }

  .approve-modal__condition-text {
    margin-right: 10px;
    width: 100%;
    height: 5em;
    resize: none;
  }

  .approve-modal__comment-message {
    @extend .__default-font-size-small;
    margin-top: -5px;
  }
}

.deny-modal__body {
  padding-right: 1.5rem;
  width: 100%;
}

.deny-modal__label {
  text-align: left;
}

.deny-modal-submit__check {
  padding-right: 5px;
}

.status-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.notified-status {
  position: absolute;
  bottom: 3%;
}