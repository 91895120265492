@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.__border {
  border: {
    width: 0.5px;
    style: solid;
    color: #d5d5d5;
    radius: 3px;
  }
}

.category-list {
  @extend .__scrollable-sidebar;
}

.category-list__content {
  @extend .__scrollable-sidebar__content;
}

.category-list__section {
  padding: {
    left: 1.25rem;
    right: 1.25rem;
  }
}

.category-list__header {
  @extend .__scrollable-sidebar__header;
}

.category-list-header__controls {
  @extend .__scrollable-sidebar-header__controls;
  justify-content: space-between;
}

.category-list__filter {
  position: relative;
  flex-grow: 1;
  margin-right: 0.8125rem;

  input {
    width: 100%;
  }

  svg {
    position: absolute;
    right: 0.5625rem;
    top: 0.5625rem;
    color: $darkGrey;
  }
}

.category-list__filter-search {
  @extend .__input;
  @extend .__border;
}

.btn-primary.category-list__add-new-category-small {
  @extend .__primary-button-small;
}

.category-list__footer {
  @extend .__scrollable-sidebar__footer;

  .category-list__add-new-category-full {
    @extend .__scrollable-sidebar-footer__button;
  }
}

.category-list__new-category-section {
  @extend .__scrollable-sidebar-header__add-new-section;
}

.category-editor__input {
  @extend .__input;
  width: 100%;
}

.category-editor__save {
  margin-left: 1.25rem;
}

.category-card__name-unselected {
  background-color: #fff !important;
  color: #000 !important;
}
