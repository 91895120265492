@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.attached-files__file-input-wrapper {
    display: inline-flex;
    align-items: center;

    .btn {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }

    margin-bottom: 0.5rem;
}

.attached-files__icon{
    color: $darkGrey;
    cursor: pointer;
}

.attached-files__file-input {
    position: fixed;
    right: 100%;
    bottom: 100%;

    label {
        display: block;
    }
}