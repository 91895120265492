@import "styles/_app.scss";
@import "styles/_variables.scss";

.grid {
    @extend .__default-font-size-small;
    border-collapse: separate;
    border-spacing: 0 $spacingBetweenCards;
    display: table;
    width: 100%;
    text-align: left;
}

.grid__header, .grid__row {
    display: table-row;
}

.grid__row {
    @extend .__card;

    &:hover {
        @extend .__card--hover;
    }
}

.grid-header__cell span{
    display: flex;
    align-items: center;
    svg{
        margin-left: 5px;
        opacity: 0.5;
    }
}

.grid-header__cell, .grid-row__cell {
    display: table-cell;
    padding: 0.5rem;
    max-width: 250px;
    vertical-align: middle;
}

.grid-header__cell--empty {
    min-width: 30px;
    margin: 0;
    padding: 0;
}

.grid-row__cell--centered {
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.grid-pill {
    display: flex;
    justify-content: center;
}

.faArrow {
    cursor: pointer;
}