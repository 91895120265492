@import '../../../styles/_app.scss';

.division-tab-container {
    @extend .__card;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    .nav-link {
        color: black;
        cursor: pointer;

        &.active {
            @extend .__card--active;
        }
    }

    .division-tab__context-menu{
        position: absolute;
        right: 10px;
        cursor: pointer;
    }

    span + svg {
        margin: {
            left: 0.625rem;
        }
    }

    &:hover {
        @extend .__card--hover;
    }
}

.edit-division-tab__container{
    margin: 0 1.25em;
    width: 100%;
}

.division-tab {
    overflow: hidden;
    width: 100%;

    padding: {
        right: 1em;
    }

    text-overflow: ellipsis;
    white-space: nowrap;
}

.type-links__container {
    padding-top: 20px;
}
