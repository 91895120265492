@import "../../styles/_variables.scss";
@import "../../styles/_app.scss";
@import "../../styles/_smcColors.scss";

.context-menu {
    color: $black100;

    &.context-menu--disabled {
        @extend .__disabled-icon;
    }
}

.context-menu--rotated {
    transform: rotate(90deg);
    display: inline-block;
}

.context-menu__item {
    padding: {
        left: 20px;
        right: 20px;
    }

    color: $darkBlue;

    &:hover {
        background-color: $smcBlue;
        color: $white;
    }

    &:first-of-type {
        border-top-left-radius: $borderRadiusDefault;
        border-top-right-radius: $borderRadiusDefault;
    }

    &:last-of-type {
        border-bottom-left-radius: $borderRadiusDefault;
        border-bottom-right-radius: $borderRadiusDefault;
    }
}

.dropdown-menu {
    padding: 0;
    border: none;
    box-shadow: 0px 3px 6px rgba($boxShadowColor, 0.16);
}
