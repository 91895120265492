@import "pages/Configuration/styles/_configuration.scss";
@import "styles/_app.scss";
@import "styles/_variables.scss";

.category-layer {
  @extend .__configuration__content;
}

.category-layer__header {
  @extend .__configuration-page-header;
  padding: 0;

  .ringi-page-content-header__title {
    @extend .__configuration-page-header__description;
    margin-bottom: 0;
  }
}

.category-layer__nav-buttons {
  margin-right: 30px;
}

.category-layer__nav-button {
  background: {
    color: $white;
  }

  border: {
    color: $buttonGroupBorderColor;
    style: solid;
    width: 1px;
  }

  color: $darkBlue;

  font: {
    family: $baseFontFamily;
    size: 0.88em;
    weight: bold;
  }

  padding: {
    bottom: 5px;
    left: 20px;
    right: 20px;
    top: 5px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    @extend .__hover-background-color;
    @extend .__hover-color;
  }

  &:first-of-type {
    border-top-left-radius: $borderRadiusDefault;
    border-bottom-left-radius: $borderRadiusDefault;
  }

  &:last-of-type {
    border-top-right-radius: $borderRadiusDefault;
    border-bottom-right-radius: $borderRadiusDefault;
  }
}

.category-layer__nav-button--selected {
  @extend .__button-selected;
}
