@import "../../styles/_app.scss";
@import "../../styles/_smcColors.scss";

.ringi-page {
  @extend .__scrollable-sidebar-parent;
}

.ringi-page__sidebar {
  @extend .__scrollable-sidebar;
}

.ringi-page__sidebar--non-primary {
  display: flex;
}

.ringi-page__content {
  @extend .__scrollable-main-content;

  padding: {
    left: 1rem;
    right: 1rem;
  }
}

.ringi-page__content-mobile {
  padding: {
    left: 0;
    right: 0;
  }
  overflow: hidden;
}

.ringi-page__content--non-primary {
  display: flex;
  flex-direction: column;
}

.ringi-page-content__header {
  @extend .__page-header-text;
  display: flex;
  align-items: center;
}

.ringi-page-content-header__title {
  margin-bottom: 0.5rem;
  display: flex;
}
