@import "styles/_app.scss";
@import "styles/_smcColors.scss";

//Start Search section
.filter-flyout-search-list__search-container{
    margin-top: 10px;
}
.filter-flyout-search-list__input{
    width: 100%;
}

.filter-flyout-search-list__buttons{
    position: relative;
    display: flex;
    float: right;
    bottom: 1.65em;
}
.filter-flyout-search-list__button{
    margin-left: 5px;
    border: none;
    border-radius: 3px;
    background: none;
    color: black;
    cursor: pointer;
}
//End Search section

.filter-flyout-search-list__options-container{
    overflow: auto;
    max-height: 75vh;

    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2px;

    div{
        width: 100%;
        align-items: center;
        border: 1px solid $gray50;
        background-color: $white;
        color: black;
        height: min-content;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
    }
}
