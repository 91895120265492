@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.ringi-input__date-range {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .validation-input {
    flex-grow: 1;
  }
}

.ringi-input-date-range__arrow-icon {
  margin: {
    top: auto;
    bottom: 10px;
    left: 4px;
    right: 4px;
  }
}
