@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.ringi-editor {
  border: {
    color: $gray10;
    style: solid;
    width: 0.5px;
  }

  box-shadow: 0 2px 4px $gray20;
  padding: 20px;

  .ringi-editor-field {
    padding: 15px 0;
  }

  .disabled {
    color: $gray50;
  }

  .attachment-field {
    padding: 0;
  }
}

.ringi-editor__fields,
.ringi-editor__attachments {
  @extend .ringi-editor;

  border: {
    radius: 3px;
    width: 1px;
  }

  margin: {
    bottom: 1.25rem;
  }

  padding: 10px;
}

.ringi-editor-attachments--required-document-error {
  color: $errorRed;

  .validation-error-message {
    @extend .__default-font-light;
    @extend .__default-font-size-small;
    @extend .__validation-tool-tip;
  }
}

.ringi-editor-attachments-required-document__icon {
  margin-left: 0.5rem;
}
