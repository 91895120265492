@import 'styles/_app.scss';

.ringi-tab {
    @extend .__card;
    @extend .__status-card;

    color: black;

    &:hover {
        @extend .__card--hover;
    }

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;

    padding: {
        left: 1.25rem;
        right: 1.25rem;
        top: 0.875rem;
        bottom: 0.875rem;
    }
}

.ringi-tab__subject {
    font-weight: initial;
    margin-bottom: 1rem;
    grid-column: span 2;
}

.ringi-tab__date {
    text-align: right;
}

.ringi-tab__type {
    font-weight: initial;
}

.ringi-tab__status {
    font-weight: initial;
    text-align: right;
}

.ringi-tab__approvalDays--container {
    display: inline-flex;
    font-weight: initial;
    font-size: 0.7rem;
    justify-content: right;
}

.ringi-tab--pill__label {
    margin-right: 5px;
    margin-top: 5px;
}