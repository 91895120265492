@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.option-list-wrapper__col {
    @extend .__default-font-size-small;
    padding: 10px 0 0 0;
}

.option-list-wrapper__col-hidden {
    display: none;
}

.option-list-wrapper__input {
    padding: 10px 0 10px 0;
}

.option-list-wrapper__x {
    position: relative;
    padding-left: 15px;
    bottom: -0.3rem;
    cursor: pointer;
}

.option-list-wrapper__button-row {
    padding-top: 15px;
}