@import "styles/_smcColors.scss";

.approval-level {
  padding: 0.75rem 1rem;
  display: flex;
  border-bottom: 1px solid $gray10;
}

.approval-level__id {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.75rem 0.5rem 0.75rem 0;
  text-align: center;
}

.approval-level__cards {
  display: flex;
  flex-wrap: wrap;
}

.approval-level__approvers {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
}

.approval-level__delete {
  font-size: 1rem;
  color: $black100;

  &:hover {
    cursor: pointer;
  }
}
