@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.edit-division-card__container {
  width: 100%;
  margin: 1.25em 0;
}

.division-edit-card__select-label {
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
    display: inline-block;
    padding-right: 10px;
    padding-top: 5px;
}

.division-edit-card__select {
  @extend .__select;
  @extend .__select-box-shadow;
  width: 100%;
}

.division-edit-card__button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
