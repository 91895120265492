@import '../../styles/_app.scss';

.cost-centers
{
    display: flex;
    flex-wrap: wrap;
}

.cost-center-header {
    margin: {
        right: 1.5rem;
    }

    .ringi-page-content-header__title {
        flex: 1 1;
    }
}

.new-cost-center-button {
    @extend .__default-font-size-small;

    svg {
        margin-right: 0.3rem;
    }
}

.disabled-toggle {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding-right: 30px;

    .disabled-toggle__label {
        @extend .__default-font;
        margin-right: 12px;

        &.disabled {
            color: $gray50;
        }
    }
}