@import 'styles/_smcColors.scss';
@import 'styles/_app.scss';

.validation-typeahead {
    @extend .__input-sensitive-group;

    .validation-typeahead__select {
        @extend .__select;
        @extend .__select-box-shadow;
        width: 100%;
    }
}

.validation-typeahead__label {
    @extend .__input-sensitive-group__label;
    display: inline-block;
    @extend .__default-font-size-small;
}

.validation-typeahead--error {
    position: relative;

    select, select:focus {
        @extend .__validation-field-error;
    }

    input {
        border-color: red;
        padding-right: 35px;
    }

    .validation-typeahead__label {
        color: $errorRed;
    }

    .validation-typeahead__icon {
        color: $errorRed;
        position: absolute;
        right: 10px;
        bottom: 8px;
    }

    .validation-error-message {
        @extend .__default-font-light;
        @extend .__default-font-size-small;
        @extend .__validation-tool-tip;
    }
}
