@import "../../styles/_app.scss";

.division-header__header {
  display: flex;
  justify-content: space-between;
}

.division-header {
  margin: 0.625em 1.5em 0 1.25em;
}

.division-page__content {
  @extend .__page__empty-text;
}

.division-page__footer {
  .division-page_add-division-page-button--large {
    @extend .__scrollable-sidebar-footer__button;
  }
}
