@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.validation-select {
  @extend .__input-sensitive-group;
}

.validation-select__label {
  @extend .__input-sensitive-group__label;
  @extend .__default-font-size-small;
  display: inline-block;
  padding-right: 10px;
  padding-top: 5px;
}

.validation-select__select {
  @extend .__select;
  @extend .__select-box-shadow;
  width: 100%;
}

.validation-select--error {
  position: relative;

  select,
  select:focus {
    @extend .__validation-field-error;
    padding-right: 30px;
  }

  .validation-select__label {
    color: $errorRed;
  }

  .validation-select__icon {
    color: $errorRed;
    position: absolute;
    right: 44px;
    bottom: 6px;
    text-align: right;
  }

  .validation-error-message {
    @extend .__default-font-light;
    @extend .__default-font-size-small;
    @extend .__validation-tool-tip;
  }
}
