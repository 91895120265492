@import 'styles/_app.scss';

.validation-boolean {
    @extend .__radio;
    flex-direction: column;
}

.validation-boolean__label {
    @extend .__default-font-size-small;
    padding-right: 10px;
}

.validation-boolean__option {
    @extend .__radio-option;
}

.validation-boolean__option-label {
    @extend .__radio-option-label;
}

.validation-boolean--error {
    .validation-boolean__label {
        color: $errorRed;
    }

    .validation-boolean__icon {
        color: $errorRed;
        margin-left: 0.25rem;
    }

    .validation-error-message {
        @extend .__default-font-light;
        @extend .__default-font-size-small;
        @extend .__validation-tool-tip;
    }
}