.instructions {
    font-weight: normal;
    padding-bottom: 20px;
}

.instructions--desktop {
    width: 50%;
}

.button-container {
    padding-top: 5px;
    padding-bottom: 5px;
}

.button-container__opt-out-all {
    font-weight: normal !important;
}