@import 'styles/_smcColors.scss';
@import 'styles/_app.scss';

.page__overlay--loading {
    @extend .__page-overlay;
    transition: opacity 0.5s, background-color 0.5s;
    z-index: 1000;
}

.fetch-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    color: $smcBlueContrast;

    &.spinner-border {
        width: 5rem;
        height: 5rem
    }
}
