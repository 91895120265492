@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.navbar-brand {
  color: $white;
  text-transform: uppercase;
  min-width: calc(100% - 100px);
  text-align: center;

  font: {
    weight: bold;
  }

  &.active,
  &:hover,
  &:focus {
    color: $white;
  }
}

.navbar-container {
  height: 51px;
  padding-top: 12px;
}

.navbar-custom {
  --On: initial;
  --Off: "";
  --avatarColor: #{$darkerBlue};
  --avatarBackgroundColor: white;
  --userProfileBackgroundColor: #{$white};
  --userProfileColor: white;
  --background-color: #{$darkBlue};
  --mobileLayout: var(--On);
  --slideoutPaddingInMobile: 20px;
  background-color: var(--background-color);
  justify-content: unset;

  @media screen and (min-width: 992px) {
    --userProfileBackgroundColor: #{$darkerBlue};
    --mobileLayout: var(--Off);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    justify-content: space-between;
  }
}

/*#region Ringi Hamburger components*/
.navbar-hamburger {
  border: none;

  .navbar-toggler-icon {
    background-image: none;
  }

  &:focus {
    outline: none;
  }
}
/*#endregion Ringi Hamburger components*/

/*#region Ringi Navbar Brand components*/
.ringi-navbar-brand {
  position: relative;
}

.ringi-navbar-brand__logo {
  height: 2.4rem;
}

.ringi-navbar-brand__logo-mobile {
  height: 1.6rem;
}

.ringi-navbar-brand__text {
  color: $white;
  display: inline-block;
  vertical-align: middle;
  line-height: 100%;

  font: {
    size: 2.25rem;
  }

  margin: {
    left: 10px;
  }
}
/*#endregion Ringi Navbar Text components*/

/*#region Ringi Navbar components*/
.ringi-navbar {
  background-color: var(--mobileLayout, $darkBlue);
  color: var(--mobileLayout, black);
  height: var(--mobileLayout, 100vh);
  left: var(--mobileLayout, 0);
  position: var(--mobileLayout, absolute);
  top: var(--mobileLayout, 0);
  z-index: 999;

  .ringi-navbar__configuration-toggle {
    @extend .ringi-navbar__link;
    background-color: $darkBlue;
    color: white;
    padding-top: 0.5rem;
    padding-left: 1.9rem;
    padding-right: 2.3rem;
    span {
      cursor: pointer;
    }
  }

  .ringi-navbar__configuration-menu {
    margin-top: 0px;
    padding-bottom: 10px;
    background-color: $darkBlue;
  }

  .configuration-context-menu__item {
    background-color: $darkBlue;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 13.5px;
    padding-right: 13.5px;
    font-size: 15px;
  }
}

.ringi-navbar__base {
  font: {
    style: normal;
    variant: normal;
    weight: normal;
    size: 1rem;
  }

  letter-spacing: 0px;
  line-height: 1.1875rem;
}

.ringi-navbar__link {
  @extend .ringi-navbar__base;
  color: $white !important;
  font-size: 15px;

  &.ringis {
    width: 6.7rem;
    text-align: center;
  }

  &.reports {
    width: 6rem;
    text-align: center;
  }

  &.active {
    font-weight: bold;
  }

  &:hover,
  &:focus {
    font-weight: bold;
  }

  @media screen and (min-width: 992px) {
    text-align: center;
    display: inline-block;

    &.active {
      color: $white !important;
      font-weight: bold;

      border: {
        bottom: 4px solid rgb(11, 112, 194);
        width: 100%;
      }

      .ringi-navbar__configuration-menu {
        margin-top: 0px !important;
        border-top: 4px solid rgb(11, 112, 194);
        border-radius: 0pt;
      }
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }
}

.ringi-navbar__link-mobile {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ringi-navbar__link-icon {
  margin-right: 40px;
  margin-left: 30px;
}

.ringi-navbar__link-container {
  width: 100%;
  background-color: $darkerBlue;
}

.ringi-navbar__link-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  border-top: 0.5px solid $darkBlue;
}

.ringi-navbar__link-row {
  padding-top: 0.5rem;
}

.ringi-navbar__version {
  @extend .__default-information-text;
  display: block;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 35px;
  color: white;
  font-size: 0.92rem;
  font-weight: normal;
  width: 100%;
  cursor: default;
}

.ringi-navbar__version-icon {
  margin-right: 10px;
  font-size: 1.25rem;
}

/*#endregion Ringi Navbar components*/
/*#region Ringi Slideout components*/
.ringi-slideout {
  width: 0;
  transition: height 0s, width 0.4s linear, padding 0.4s linear;
  white-space: var(--mobileLayout, pre);
  overflow: var(--mobileLayout, hidden);
  padding-left: 0;
  padding-right: 0;
  top: 58px;

  &.collapse:not(.show) {
    display: initial;
  }
}

.ringi-slideout--visible {
  width: 100vw;
}

.navbar-back-arrow {
  border: none;

  .navbar-toggler-icon {
    background-image: none;
  }

  &:focus {
    outline: none;
  }
}

/*#endregion Ringi Slideout components*/
