@import 'styles/_smcColors.scss';
@import 'styles/_app.scss';

.action-modal {
    @extend .__default-font;

    font: {
        size: 1rem;
    }

    line-height: 1.4375rem;
    display: flex !important;
    justify-content: center;
    color: rgb(44, 44, 44);

    button + button {
        margin: {
            left: 30px;
        }
    }
}

.action-modal__header {
    border: {
        bottom: none;
    }

    width: 100% !important;
    display: inline-flex;

    padding: {
        bottom: 0;
    }
}

.action-modal--warning {
    .action-modal-header__title {
        color: $errorRed;
    }

    .action-modal__button {
        @extend .__danger-button;
    }
}

.action-modal--confirmation {
    .action-modal-header__title {
        color: $darkerBlue;
    }

    .action-modal__button {
        @extend .__primary-button;
    }
}

.action-modal-header__title {
    font: {
        size: 1.15em;
        weight: bold;
    }
}

.action-modal__body {
    font: {
        size: 0.9em;
        weight: normal;
    }

    text-align: left;
    white-space: pre-line;

    padding: {
        top: 1.875rem;
        bottom: 1.375rem;
    }
}

.action-modal__section {
    font: {
        size: 0.9em;
        weight: normal;
    }

    text-align: left;
    white-space: pre-line;

    padding: {
        left: 1.0rem;
        top: 1.0rem;
        right: 1.0rem;
        bottom: 0;
    }
}

.action-modal__footer {
    border: {
        top: none;
    }

    justify-content: center;
}

.action-modal-button__icon {
    margin: {
        right: 10px;
    }
}