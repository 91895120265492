@import "styles/_smcColors.scss";

tr.report-summary__dropdown {
  background-color: $white !important;
  &.closed {
    position: absolute;
    overflow: hidden;
    transition: height 0.4s linear, padding 0.4s linear;
    height: 0;
  }
  td {
    &:first-child {
      padding-left: 30px;
      font-weight: normal;
    }
  }
}
tr.report-summary__header {
  background-color: rgba(0, 0, 0, 0.05) !important;
  height: 53px;
  cursor: pointer;
  .report-summary__header-icon {
    margin-right: 10px;
    display: inline-block;
    &.closed {
      rotate: 180deg;
      transition: rotate 0.4s;
    }
  }
}
