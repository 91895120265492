@import '../../../styles/_smcColors.scss';
@import '../../../styles/_app.scss';

.add-approver-editor-content{
    &:hover {
        @extend .__card--hover;
    }

    @extend .__card-box-shadow;
    border-width: 1px;
    padding: 1.25rem;
    width: 300px;
    margin: 0.5rem;
}

.approver-editor__buttons {
    @extend .__button-container;
}

.approver-editor__form {
    label {
        @extend .__default-font;
    }
    text-align: left;
}

.add-approver-editor-body__typeahead {
    label {
        margin-top: 15px;
    }
}
.approver-editor__buttons {
    @extend .__button-container;
}

.approver-editor__display-text {
    display: inline-block;
    margin-top: 0.75rem;
    margin-left: 1.0rem;
}