﻿@import "styles/_smcColors.scss";
@import "styles/_variables.scss";

//TODO: REFACTOR - sort these alphabetically.
.__default-font {
  font: {
    family: "Roboto", sans-serif;
    weight: bold;
  }
}

.__default-font-light {
  @extend .__default-font;

  font: {
    weight: normal;
  }
}

.__default-font-size-small {
  font: {
    size: 0.875rem;
  }
}

.__default-font-size-x-small {
  font: {
    size: 0.725em;
  }
}

.__empty-text {
  color: $bluegray;
  margin-left: auto;
  margin-right: auto;
}

.__page__empty-text {
  @extend .__empty-text;
  margin-top: auto;
  margin-bottom: auto;

  font: {
    size: 1.25rem;
  }
}

.__input {
  height: 2.125rem;
  padding: 0 0.625rem;

  outline: {
    color: $smcBlue;
  }
}

.__input-border {
  border: {
    width: 0.5px;
    style: solid;
    color: #d5d5d5;
    radius: 3px;
  }
}

.__input-sensitive-group {
  &:focus-within {
    color: $smcBlue;
  }
}

.__input-sensitive-group__label {
  color: $darkerBlue;
}

.__primary-button {
  @extend .__default-font;
  @extend .__default-font-size-small;
  @extend .__default-font-light;
  --button-main-color: #{$smcBlue};
  --button-active-color: #{$darkBlue};
  --button-hover-focus-color: #{$smcBlueContrast};

  background: {
    color: var(--button-main-color);
  }

  border: {
    color: var(--button-main-color);
    radius: 5px;
  }

  max-height: 2.375rem;
  padding: 0.5625rem 1.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  color: $white;
  line-height: 1.1875rem;

  &:not(:disabled):not(.disabled) {
    &:active {
      background-color: var(--button-active-color);
      border-color: var(--button-active-color);

      &:focus {
        box-shadow: none;
        background-color: var(--button-active-color);
        border-color: var(--button-active-color);
      }
    }

    &:hover,
    &:focus {
      background-color: var(--button-hover-focus-color);
      border-color: var(--button-hover-focus-color);
    }
  }
}

.__success-button {
  @extend .__primary-button;
  --button-main-color: #{$positiveGreen};
  --button-active-color: #046600;
  --button-hover-focus-color: #08ad02;
}

.__danger-button {
  @extend .__primary-button;
  --button-main-color: #{$errorRed};
  --button-active-color: #a20004;
  --button-hover-focus-color: #ff0000;
}

.__disabled-button {
  @extend .__primary-button;
  --button-main-color: darkgrey;
  --button-hover-focus-color: #fff;

  cursor: not-allowed;
}

.__text-area {
  @extend .__input-border;
  height: 4.25rem;

  padding: {
    top: 0.25rem;
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }

  outline: {
    color: $smcBlue;
  }

  box-shadow: 0px 2px 3px rgba($boxShadowColor, 0.16);
}

.__select {
  @extend .__input-border;
  height: 2.125rem;
  padding: 0 0.625rem;

  outline: {
    color: $smcBlue;
  }
}

.__select-box-shadow {
  box-shadow: 0px 2px 3px rgba($boxShadowColor, 0.16);
}

.__radio {
  display: flex;
}

.__radio-option {
  margin-top: auto;
  margin-bottom: auto;
}

.__radio-option-label {
  @extend .__default-font-size-small;
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.__primary-button-small {
  @extend .__primary-button;
  padding: 0.625rem;
  height: 2.125rem;
  line-height: 0.875rem;
}

.__card-box-shadow {
  @extend .__input-border;
  box-shadow: 0px 1px 3px rgba($boxShadowColor, 0.1);
}

.__hover-background-color {
  background: {
    color: $smcBlueContrast;
  }
}

.__hover-color {
  color: $white;
}

.__button-selected {
  background: {
    color: $darkBlue !important;
  }

  color: $white !important;
}

.__icon {
  cursor: pointer;
}

.__disabled-icon {
  color: lightgray;
  cursor: not-allowed;
}

.__section {
  border: {
    color: rgba($darkBlue, 0.2);
    style: solid;
    width: 0.5px;
  }

  box-shadow: 0px 2px 4px rgba($boxShadowColor, 0.16);
  padding: 10px;
  margin-top: 10px;
}

.__section-name {
  font: {
    family: $baseFontFamily;
    weight: bold;
  }

  margin: {
    left: 10px;
  }
}

.__card {
  background-color: $white;

  margin: {
    bottom: $spacingBetweenCards;
  }

  border: {
    color: #d8d8d8;
    radius: 3px;
    style: solid;
    width: 0.5px;
  }

  width: 100%;

  box-shadow: 0px 2px 4px rgba($boxShadowColor, 0.16);
}

.__card--hover {
  background-color: $lightBlue;

  border: {
    color: $smcBlueContrast;
  }
}

.__card--active {
  background-color: $smcBlue;
  color: $white;

  border: {
    color: $smcBlueContrast;
  }
}

.__card--disabled {
  cursor: pointer;
  background-color: $disabledGray;

  span,
  label {
    color: $mutedText;
  }

  &:hover {
    background-color: whitesmoke;
    border-color: silver;
    cursor: pointer;
  }

  .context-menu {
    color: initial;
  }
}

.__card--readonly {
  cursor: pointer;
  background-color: $white;

  &:hover {
    background-color: whitesmoke;
    border-color: silver;
    cursor: default;
  }

  .context-menu {
    color: initial;
  }
}

.__card__title {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $darkGrey;
  flex: 0 0 100%;
  margin-bottom: 0.90625rem;
}

.__card__cancel-button {
  --midway: 50%;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: var(--midway);
  transform: translateY(calc(-1 * var(--midway)));
}

.__status-card {
  border-left: 0.625rem solid $gray20;
}

.__scrollable-sidebar-parent {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
}

.__scrollable-sidebar {
  background: {
    color: rgba($lightBlue, 0.27);
    repeat: no-repeat;
    clip: padding-box;
  }

  padding: {
    left: 0;
    right: 0;
  }

  box-shadow: 4px 0px 10px rgba($boxShadowColor, 0.09);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.__scrollable-sidebar__header {
  display: flex;
  flex-wrap: wrap;

  margin: {
    top: 0.625rem;
    bottom: 1.25rem;
  }
}

.__scrollable-sidebar-header__controls {
  display: flex;
  flex-basis: 100%;
}

.__scrollable-sidebar-header__add-new-section {
  padding: {
    top: 1.5rem;
    left: 0;
    right: 0;
  }

  margin-bottom: 0.8125rem;
  flex-grow: 1;
}

.__scrollable-sidebar-header__context-menu {
  margin: {
    left: 0.5rem;
    right: 0.5rem;
    top: auto;
    bottom: auto;
  }
}

.__scrollable-sidebar__content {
  flex: 1;
  overflow: auto;
}

.__scrollable-sidebar__footer {
  position: sticky;
  left: 0;
  bottom: 0;
}

.__scrollable-sidebar-footer__button {
  @extend .__primary-button;
  width: 100%;
  border-radius: 0;
  height: 100%;

  svg {
    margin-right: 0.5rem;
  }
}

.__scrollable-main-content {
  flex: 1;
  overflow: auto;
}

.__page-header-text {
  font-size: 1.25rem;

  padding: {
    top: 1.5rem;
    bottom: 1rem;
  }
}

.__back-arrow {
  color: black;
  cursor: pointer;

  padding: {
    right: 0.875rem;
  }
}

.__button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: {
    top: 0.5rem;
  }
}

.__validation-field-error {
  border: {
    color: $errorRed;
  }

  color: $errorRed;

  outline: {
    color: $errorRed;
  }
}

.__validation-tool-tip {
  line-height: 1rem;
  letter-spacing: -0.34px;

  background: {
    color: $errorRed;
  }

  box-shadow: 0px 6px 16px rgba($boxShadowColor, 0.16);
  border-radius: 3px;

  &.show {
    opacity: 1;
  }
}

.__page-overlay {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay;
  z-index: 998;
  cursor: pointer;
}

.__default-information-text {
  @extend .__default-font;
  @extend .__default-font-light;
  font-size: 0.75rem;
}

.__approved-status-text {
  color: $positiveGreen;
  font-weight: bold;
  text-transform: uppercase;
}

.__denied-status-text {
  color: $errorRed;
  font-weight: bold;
  text-transform: uppercase;
}

.__cancelled-status-text {
  color: $darkGrey;
  font-weight: bold;
  text-transform: uppercase;
}

.__pending-status-text {
  color: $smcBlue;
  font-weight: bold;
  text-transform: uppercase;
}

.__paused-status-text {
  color: $warningYellow;
  font-weight: bold;
  text-transform: uppercase;
}

.__out-of-focus {
  opacity: 0.2;
  cursor: default;

  &:hover,
  &:focus {
    background-color: initial;
    border-color: $gray20;
    cursor: default;
  }
}
