@import "styles/_app.scss";

.ringi-list__cell-text {
    font-weight: normal;
}

.ringi-list__ringi-number-cells{
    min-width: 130px;
}
.grid-row__cell:first-child {
    @extend .__status-card;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.grid__row--approved {
    .ringi-list__status {
        @extend .__approved-status-text;
        //color: $positiveGreen;
    }

    .grid-row__cell {
        border-left-color: $positiveGreen;
    }
}

.grid__row--denied {
    .ringi-list__status {
        @extend .__denied-status-text;
        //color: $errorRed;
    }

    .grid-row__cell {
        border-left-color: $errorRed;
    }
}

.grid__row--draftError {
    .ringi-list__status {
        @extend .__denied-status-text;
        //color: $errorRed;
    }

    .grid-row__cell {
        border-left-color: $errorRed;
    }
}

.grid__row--cancelled {
    .ringi-list__status {
        @extend .__denied-status-text;
        color: $darkGrey;
    }

    .grid-row__cell {
        border-left-color: $darkGrey;
    }
}

.grid__row--pending {
    .ringi-list__status {
        @extend .__pending-status-text;
        //color: $smcBlue;
    }

    .grid-row__cell {
        border-left-color: $smcBlue;
    }
}

.grid__row--paused {
    .ringi-list__status {
        @extend .__paused-status-text;
        //color: $warningYellow;
    }

    .grid-row__cell {
        border-left-color: $warningYellow;
    }
}

.grid__row--approved .ringi-list__status {
    color: $positiveGreen;
}

.ringi-tab--approved {
    border-left-color: $positiveGreen;

    &:hover {
        border-left-color: $positiveGreen;
    }
}

.ringi-tab--denied {
    border-left-color: $errorRed;

    &:hover {
        border-left-color: $errorRed;
    }
}

.ringi-tab--draftError {
    border-left-color: $errorRed;

    &:hover {
        border-left-color: $errorRed;
    }
}

.ringi-tab--cancelled {
    border-left-color: $darkGrey;

    &:hover {
        border-left-color: $darkGrey;
    }
}

.ringi-tab--pending {
    border-left-color: $smcBlue;

    &:hover {
        border-left-color: $smcBlue;
    }
}

.ringi-tab--paused {
    border-left-color: $warningYellow;

    &:hover {
        border-left-color: $warningYellow;
    }
}

.ringi-list__nav-item {
    display: contents;
    color: black;
}

.ringi-list__nav-item:hover {
    text-decoration: none;
    color: black;
}
