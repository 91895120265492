
@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.draft-ringi__cancel {
    margin-right: 1.25rem;
}

.draft-ringi__save.btn-primary {
    @extend .__primary-button;
    margin-right: 0.5rem;

    svg {
        margin-right: 0.5rem;
    }
}

.draft-ringi__submit.btn-primary {
    @extend .__primary-button;

    svg {
        margin-right: 0.5rem;
    }
}

.draft-ringi-page {
    .validation-error-message {
        @extend .__default-font-light;
        @extend .__default-font-size-small;
        @extend .__validation-tool-tip;
    }
}

.draft-ringi-page__icon {
    color: $errorRed;
    margin-right: 0.5rem;
    display: inline-flex;
}

.draft-ringi-page__header {
    @extend .__page-header-text;
}

.draft-ringi-page__buttons {
    margin-left: auto;
}