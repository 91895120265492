@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.approver-menu__button-bar {
    @extend .__scrollable-sidebar__footer;
    padding: 0.625rem;
    background-image: $smcDarkBlueGradient;
    display: flex;
    height: 60px;
}

.add-additional-approver {
    display: block;
    flex-wrap: wrap;
    width: 100%;

    .validation-typeahead {
        flex-basis: 100%;

        label {
            color: $white;
            font-weight: initial;
        }
    }
}

.add-approver-bar__approver-container {
    padding-top: 1px;
    display: grid;
    grid-template-columns: auto 50px;
    column-gap: 5px;
    align-items: center;

    .add-approver-bar__add-approver {
        justify-self: end;
    }
}

.add-approver-button {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}