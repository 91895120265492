@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.reader-list__readers {
  @extend .__scrollable-sidebar__content;
  padding: 0.625rem;
}

.reader-list__reader {
  @extend .__card;
  @extend .__default-font-light;
  padding: 1rem;

  .reader-subtext {
    font-size: 0.8rem;
    color: $mutedText;
    margin-bottom: 3px;
  }

  .reader-detail__flex {
    flex-grow: 1;
    display: flex;
    margin-bottom: 0.3rem;
    justify-content: space-between;
    flex-wrap: wrap;
    .unread-dot {
      display: none;
      width: 20px;
      text-align: center;
      font-size: x-small;
      color: $smcBlueContrast;
      svg {
        margin-right: 5px;
      }
    }
  }
  .unread {
    .unread-dot {
      display: block;
    }
    .reader-list__name {
      font-weight: bold;
    }
  }

  .reader-detail__grid-last-cell {
    span {
      padding-left: 5px;
      font: {
        size: 0.8rem;
      }
    }
  }

  .reader-costcenter__detail {
    display: flex;
    flex-direction: column-reverse;
  }
}

.reader-detail__button {
  cursor: pointer;
}
