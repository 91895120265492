.preference-container {
    border-top: 2px solid #ddd;
}

.preference-container--last {
    border-bottom: 2px solid #ddd;
}

.preference-container--desktop {
    width: 50%;
}

.preference-row {
    display: flex;
    padding: 5px;
}

.preference-column {
    padding-right: 10px;
}

.preference-title {
    margin-bottom: 0;
}

.preference-description {
    font-weight: normal;
}