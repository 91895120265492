@import "../../styles/_smcColors.scss";
@import "../../styles/_variables.scss";

.error-tooltip {
  .tooltip-inner {
    background-color: $errorRed !important;
  }

  div.arrow::before {
    border-bottom-color: $errorRed !important;
    border-top-color: $errorRed !important;
    color: $white !important;
  }
}
