@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.division-editor-content {
  height: 90%;
}

.division-editor {
  border: {
    color: $gray10;
    radius: 3px;
    width: 1px;
  }
  height: 100%;
  margin-bottom: 1.25rem;
  padding: 30px;
  box-shadow: 0 2px 4px $gray20;
}

.division-editor__header {
  padding-top: 0.75rem;
}

.division-editor__sub-header {
    padding-bottom: 0.75rem;
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
    @extend .__default-font-light;
}

.new-division-email__button {
  margin-top: 0.75rem;
}

.division-editor-save__button {
  font-weight: normal;
  align-content: end;
}

.division-editor__selected-emails {
  min-height: 180px;
  max-height: 70%;
  overflow-y: auto;
}

.division-editor__selected-emails-outer-container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 400px 200px;
  grid-template-rows: auto;
}

.division-editor__selected-emails-primary-container {
  margin-top: 13px;
  grid-column-start: 2;
  grid-column-end: 3;
  font-weight: normal;
}

.division-editor__selected-emails-container {
  border-radius: 25px;
  border: 1px solid $darkGrey;
  padding: 10px;
  width: 380px;
  height: 50px;
  margin-bottom: 8px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 40px 300px;
  grid-template-rows: auto;
  grid-column-start: 1;
  grid-column-end: 2;
}

.division-editor__selected-emails-email-icon {
  margin-left: 5px;
  margin-top: 5px;
  grid-column-start: 1;
  grid-column-end: 2;
}

.division-editor__selected-emails-name {
  font-size: 0.93em;
  font-weight: bold;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: -5px;
}

.division-editor__selected-emails-email {
  font-size: 0.82em;
  font-weight: normal;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: -5px;
}

.division-editor__selected-emails-remove-icon {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  grid-column-start: 5;
  margin-top: -15px;
}

.division-editor__add-email-container {
  margin-top: 25px;
  margin-bottom: 25px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  max-width: 50%;
  position: fixed;
  bottom: 4rem;
}

.division-editor__add-email-field-group {
  grid-column-start: 1;
  grid-column-end: 3;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.division-editor__add-full-name {
  grid-column-start: 1;
  grid-column-end: 2;
}

.division-editor__add-email-address {
  margin-left: 20px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.division-editor__add-email-label {
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
}

.division-editor__add-full-name-input {
  width: 100%;
  min-width: 250px;
}

.division-editor__add-email-input {
  width: 100%;
  min-width: 250px;
}

.division-editor__add-email-button {
  margin-left: 10px;
  grid-column-start: 3;
  margin-top: 32px;
  min-width: 120px;
}

.division-editor-save__button {
  min-width: 120px;
  position: absolute;
  margin-bottom: 25px;
  margin-top: 25px;
  bottom: 10px;
  right: 3rem;
}

.division-editor-save__container {
  text-align: end;
}

.division-editor__full-name-error {
  position: relative;
  left: 14em;
  bottom: 2em;
}

.division-editor__email-error {
  position: relative;
  left: 14em;
  bottom: 2em;
}

.division-editor__selected-emails-empty {
  color: red;
  font-weight: normal;
}

.division-editor__selected-emails-primary-label {
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
    margin-left: 10px;
    margin-right: 10px;
}
