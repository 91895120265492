@import "../../../styles/_app.scss";
@import "../../../styles/_smcColors.scss";

.new-template-field-container {
  @extend .__card;
  @extend .__card-box-shadow;

  font: {
    size: 1rem;
  }

  margin: {
    top: 15px;
  }

  padding: 1.25rem;
  width: 450px;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  font-weight: bold;
}
