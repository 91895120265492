@import '../../styles/app';
@import '../../styles/smcColors';

.notification-empty-message{
    text-align: center;
    padding: 3%;
}

.notifications-list-container__fullscreen{
    padding: 1% 20%;
}

.notifications-list-container__mobile{
    padding: 1% 3%;
}

.notification-list__mark-all-read{
    text-align: -webkit-right;
    text-align: -moz-right;
    padding-bottom: 1%;

    svg{
        margin-left: 8px;
    }

    .notifications-list-mark-all-read__button{
        cursor: pointer;
        width: max-content;
    }
}

.notifications-list-notification{
    display: flex;
    align-items: center;
}

.notification-list-notification-container{
    display: flex;
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid $lightGrey;
    align-items: center;
    justify-content: space-between;
}

.notifications-list-notification__read{
    @extend .notification-list-notification-container;
    font-weight: normal;
    .notifications-list-notifications__url{
        color: $mutedText;
    }
}

.notifications-list-notification__unread{
    @extend .notification-list-notification-container;
    .notifications-list-notifications__url{
        color: $smcBlueContrast;
    }
}

.notifications-list-notifications__url{
    text-decoration: underline;
    cursor: pointer;
}

.notifications-list-notification__unread-dot{
    width: 70px;
    text-align: center;
    font-size: x-small;
    color: $smcBlueContrast;
    svg{
        margin-right:5px;
    }
}
.notifications-list-notification__read-dot{
    width: 70px;
}

.notifications-list__button{
    padding-left: 30px;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;
}

.notifications-list-notification:hover .notifications-list__button{
    opacity: 1;
}

.notifications-list-notification__message{
    width: 100%;
    padding: 10px 10px 10px 0;
}

.notifications-list-notification__time{
    width: 30%;
    text-align: right;
}