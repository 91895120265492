@import "styles/_smcColors.scss";

.summary-report {
  display: flex;
  padding: 20px 0;

  @media screen and (min-width: 992px) {
    overflow: hidden;
    height: calc(100vh - 58px);
  }

  .ringi-page__content {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (min-width: 992px) {
      overflow: hidden;
    }

    .summary-report__header-row {
      display: flex;
      justify-content: space-between;
    }

    .summary-report__card-wrapper {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 992px) {
        overflow: hidden;
        flex: 1;
        flex-direction: row;
      }

      > div {
        margin-bottom: 20px;
        display: block;
        flex: 1;
        height: 100%;

        @media screen and (min-width: 992px) {
          display: flex;
          flex: 1;
          height: 100%;
          overflow: hidden;
        }
      }
    }
  }
  .description {
    font-weight: 300;
    color: $mutedText;
  }
}
