@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.user-profile-notification {
  display: flex;
  width: 70px;
  margin-left: 20px;
}

.user-profile-notification__mobile {
  left: calc(100% - 95px);
  position: absolute;
}

.user-profile-notification__icon {
  background: $white;
  color: $darkerBlue;
  border-radius: 50%;
  padding: 5px 10px;
  height: 35px;
  width: 35px;
  align-self: center;
  cursor: pointer;
}

.user-profile-notification__number {
  background: $smcBlue;
  color: $white;
  border-radius: 15px;
  padding: 5px 8px 5px 8px;
  font-size: 13px;
  min-width: 30px;
  text-align: center;
  height: fit-content;
  position: relative;
  left: -8px;
  top: 4px;
  cursor: pointer;
}
