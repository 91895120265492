@import '../../../styles/_app.scss';

.__cost-center-card {
    @extend .__card;

    padding: 1.25rem;
    width: 28rem;

    margin: {
        right: 1.5rem;
        top: 1.25rem;
    }

    &:hover {
        @extend .__card--hover;
    }
}

.cost-center-tab {
    @extend .__cost-center-card;
    display: flex;
    height: 100%;
}

.cost-center-tab__content {
    flex-grow: 1;
}

.cost-center-tab--disabled {
    @extend .__card--disabled;
}

.cost-center-tab__options {
    display: inline-block;
}