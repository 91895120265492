@import 'pages/Configuration/styles/_configuration.scss';
@import 'styles/_app.scss';
@import 'styles/_variables.scss';

.type-layer {
    @extend .__configuration__content;
}

.type-layer__header {
    @extend .__configuration-page-header;
    padding: 0;

    .ringi-page-content-header__title {
        @extend .__configuration-page-header__description;
        margin-bottom: 0;
    }
}

.type-layer__enable-type {
    @extend .__primary-button;

    svg {
        margin: {
            right: 0.5rem;
        }
    }
}

.type-layer__error-icon {
    margin-right: 0.5rem;
}