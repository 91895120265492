@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.read-only-ringi-panel__title {
    @extend .__page-header-text;
}

.read-only-ringi-panel-card__title {
    margin-bottom: 1rem;
    font-weight: normal;
    color: $darkerBlue;
}

.read-only-ringi-panel-card__attachment {
    .read-only-ringi-panel-card-attachment__link {
        font-weight: normal;
    }
}

.edit-afc-container{
    @extend .__card;
}