@import 'styles/_app.scss';
@import 'styles/_smcColors.scss';
@import 'pages/Configuration/Type/FormBuilder.scss';

.type-approval-template-editor__name {
    @extend .__section-name;
}

.type-approval-template-editor {
    @extend .type-approval-editor__approval-container;

    & > * {
        flex: auto;
    }

    & .validation-select__label{
        margin-bottom: 0 !important;
    }
}

.type-approval-template-editor__buttons {
    @extend .type-approval-editor-card__buttons;
    padding-top: 0;
}

.type-approval-template-form__field {
    width: 240px;
}