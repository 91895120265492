@import "styles/_smcColors.scss";
@import "styles/_app.scss";

.validation-input {
  @extend .__input-sensitive-group;
}

.validation-input__label {
  @extend .__input-sensitive-group__label;
  @extend .__default-font-size-small;
  padding-right: 10px;
  padding-top: 5px;
}

.validation-input-wrapper__input {
  @extend .__input;
  @extend .__input-border;
  box-sizing: border-box;
  display: block;
  width: 100%;

  &:focus {
    box-shadow: 0px 3px 6px rgba(#0000ff, 0.16);
  }
}

.validation-input-wrapper__prefix-icon,
.validation-input-wrapper__suffix-icon {
  margin: {
    top: auto;
    bottom: auto;
  }
}

.validation-input-wrapper__prefix-icon {
  margin-right: 5px;
}

.validation-input-wrapper__suffix-icon {
  margin-left: 5px;
}

.validation-input-wrapper__clear-icon {
  color: rgb(26, 26, 26);
  cursor: pointer;
}

.clear-icon-container {
  display: flex;
  align-items: center;
}

.validation-input--error {
  position: relative;

  .validation-input-wrapper__input,
  .validation-input-wrapper__input:focus,
  .validation-input__wrapper--with-prefix,
  .validation-input__wrapper--with-suffix {
    @extend .__validation-field-error;

    &:focus-within {
      border: {
        color: $errorRed;
      }
    }
  }

  .validation-input__label {
    color: $errorRed;
  }

  .validation-input-wrapper__icon {
    color: $errorRed;
    position: absolute;
    right: 44px;
    bottom: 5px;
  }

  .validation-input-wrapper__prefix-icon {
    color: $errorRed;
  }

  .validation-error-message {
    @extend .__default-font-light;
    @extend .__default-font-size-small;
    @extend .__validation-tool-tip;
  }
}

.validation-input__wrapper {
  position: relative;
  display: flex;
}

.validation-input__wrapper--with-prefix,
.validation-input__wrapper--with-suffix {
  @extend .__input;
  @extend .__input-border;
  display: flex;

  &:focus-within {
    border-color: $smcBlue;
    border-width: 2px;
  }

  .validation-input-wrapper__input {
    @extend .__input;
    padding: 0;
    border: none;
    outline: none;
    height: 95%;
    flex-grow: 1;

    &:focus {
      box-shadow: none;
    }
  }
}
