@import "../../../styles/_app.scss";
@import "../../../styles/_smcColors.scss";

/*#region Data Entry Container components*/
.data-entry-container {
  display: inline-block;
  width: 100%;

  padding: {
    right: 0.8125rem;
  }

  margin-bottom: 0.50rem;
}

.data-entry-option-a__label {
  & input {
    margin: 0px 5px 0 20px !important;
  }
  & span {
    font-size: 0.875rem !important;
  }
}
/*#endregion Data Entry Container components*/

/*#region Data Entry Field Group components*/
.data-entry-field-group {
  @extend .__input-sensitive-group;
}

.data-entry-field-group__label {
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
    display: inline-block;
    padding-right: 10px;
}

.data-entry-field-group__checkboxLabel {
    @extend .__input-sensitive-group__label;
    @extend .__default-font-size-small;
    display: inline-block;
    padding-left: 10px;
    margin-bottom: 0;
}

.data-entry-field-group__input {
  @extend .__input;
  @extend .__input-border;
  /*@extend .__input-box-shadow;*/
  box-sizing: border-box;
  width: 100%;
  display: block;

  &:focus {
    box-shadow: 0px 3px 6px rgba(#0000ff, 0.16);
  }
}

.data-entry-field-group__textarea {
  @extend .__text-area;
  width: 100%;
  display: block;
}

.data-entry-field-group__select {
  @extend .__select;
  @extend .__select-box-shadow;
  width: 100%;
}

.data-entry-field-group__radio {
  @extend .__radio;
}

.data-entry-field-group__radio-option {
  @extend .__radio-option;
}

.data-entry-field-group__radio-option-label {
  @extend .__radio-option-label;
}
/*#endregion Data Entry Field Group components*/
/*#region Template Field Container components*/
.template-field-container {
  @extend .__card;
  @extend .__card-box-shadow;

  font: {
    size: 1rem;
  }

  margin: {
    top: 20px;
  }

  padding: 1.25rem;
  width: 450px;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.template-field-container--read-only {
  flex-direction: row;
}

.template-field-container--disabled {
  @extend .__card--disabled;
}

.panel-icon {
  @extend .__icon;

  margin: {
    bottom: 5px;
  }
}

.panel-icon--disabled {
  @extend .__disabled-icon;
}

.panel-icon + .panel-icon {
  margin: {
    top: 10px;
  }
}

.field-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field-data--column {
  display: flex;
  flex-direction: column;

  & > div {
    padding: 5px;
  }

  & > div > span {
    padding-left: 10px;
  }
}
/*#endregion Template Field Container components*/
