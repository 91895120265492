﻿$darkGrey: #3e3e3e;
$lightGrey: #e0e0e0;
$white: #ffffff;
$smcBlue: #1a75cf;
$smcBlueContrast: #2190ff;
$smcBlueTrack: #d6e2eb;
$lightBlue: #d6ebff;
$darkBlue: #0d3b68;
$darkerBlue: #071e34;
$positiveGreen: #059100;
$errorRed: #e60000;
$mutedText: #6c757d;
$disabledGray: #dcdcdc;
$draftGray: #f8f4f4;
$warningYellow: #febe10;
$smcDarkBlueGradient: linear-gradient($darkBlue -0.1%, $darkerBlue 99.9%);
$bluegray: #5d7c9b;
$black100: #000000;
$boxShadowColor: $black100;
$gray5: #555;
$gray10: #cfd8e1;
$gray20: #d6d6d6;
$gray50: #8e8e8e;
$gray100: #2c2c2c;
$overlay: rgba($darkerBlue, 0.9);
