@import 'styles/_app.scss';
.type-panel-editor__disabled-name{
    width: 100%;
}
.type-panel-editor__zendesk{
    display: flex;
    column-gap: 8px;
}

.type-panel-editor__zendesk-text{
    @extend .__default-font-size-small;
    padding-top: 1em;
}

.type-panel-editor__zendesk-subtext{
    @extend .__default-font-light;
    font-size: 0.8em;
}

.type-panel-editor__buttons {
    @extend .__button-container;
    display: flex; 
    justify-content: space-between;
 
}

.type-panel-editor__save-type-name {
    margin-left: 20px;

    svg {
        margin: {
            right: 0.5rem;
        }
    }
}

.type-panel-editor {
    width: 100%;
}