@import "styles/_smcColors.scss";

.report-table {
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0;
  margin-bottom: 0;
  width: 100%;

  thead {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: inset 0 -1px 0 $lightGrey;
    th {
      color: $mutedText;
      font-size: 1rem;
      border-top-style: hidden !important;
      border-left-style: hidden !important;
      border-right-style: hidden !important;
    }
  }

  td {
    border-left-style: hidden !important;
    border-right-style: hidden !important;
    vertical-align: middle;
    font-weight: 700;
  }
}
