@import '../../styles/app';
@import '../../styles/smcColors';

.notifications__header{
    display: flex;
    padding: 0 20%;
    text-align: center;
    margin-top: 50px;
    border-bottom: 2px solid $smcBlue;
    height: 50px;

    div{
        width: 200px;
        line-height: 50px;
        cursor: pointer;

        .notifications__notification-number{
            background: $smcBlue;
            color: $white;
            border-radius: 15px;
            padding: 5px 9px 5px 8px;
            font-size: 10px;
            height: fit-content;
            position: relative;
            left: 5px;
            top: -12px;
        }

        &.active{
            background-color: $smcBlue;
            color: $white;
            .notifications__notification-number{
                background-color: $white;
                color: $black100;
            }
        }
    }
}

.notifications__mobile-header{
    @extend .notifications__header;
    padding: 0 3%;
}

.infinite-scroll-component{
    overflow: inherit !important;
}

