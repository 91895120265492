@import 'pages/Configuration/styles/_configuration.scss';
@import 'styles/_app.scss';

.base-layer {
    @extend .__configuration__content;
}

.base-layer__header {
    @extend .__configuration-page-header;
    padding: 0;

    .ringi-page-content-header__title {
        @extend .__configuration-page-header__description;
        margin-bottom: 0;
    }
}

.header-controls-placeholder { 
    width: 125px;
}