@import 'styles/_smcColors.scss';
@import 'styles/_app.scss';

.user-profile-container {
    display: flex;

    background: {
        color: var(--userProfileBackgroundColor);
    }

    padding: {
        bottom: 0.5rem;
        left: 0.625rem;
        right: 0.625rem;
        top: 0.5rem;
    }
}

.user-profile-container-mobile {
    background-color: $darkBlue;
    margin-top: 10px;
    margin-bottom: 12px;
}

.user-profile {
    color: var(--userProfileColor);
    cursor: pointer;
    position: relative;

    font: {
        size: 0.9rem;
    }

    padding-left: 35px;
    max-width: 500px;
    min-width: 300px;
}

.user-profile-mobile-container {
    width: 100%;
    align-content: center;
}

.user-profile-mobile {
    padding-left: 0px;
    min-width: 80px;
    max-width: unset;
    margin: auto;
    width: fit-content;
    font-weight: normal;
}

.user-profile--sections {
    display: flex;
}

.user-profile--sections-mobile {
    display: flex;
    margin-left: -42px;
}

.user-profile__avatar {
    --mid-point: 50%;
    --length: 34px;

    background: {
        color: var(--avatarBackgroundColor);
    }

    border: {
        radius: var(--mid-point);
    }

    height: var(--length);
    position: relative;
    width: var(--length);
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-profile-avatar__icon {
    color: var(--avatarColor);
}

.user-profile__position {
    font: {
        size: 0.66rem;
        weight: normal;
    }
}

.user-profile__user-info {
    flex-grow: 1;
    margin-left: 10px;
}

.user-profile__name--fullHeight {
    line-height: 34px;
}

.user-profile__slideout-actuator {
    margin-top: auto;
    margin-bottom: auto;
}

.user-profile-slideout-actuator__caret {
    transform: rotate(0);
    transition: transform 0.5s;
}

.user-profile-slideout-actuator__caret--rotated {
    transform: rotate(-180deg);
}

.user-profile__slideout {
    background: {
        color: $darkerBlue;
    }

    color: white;
    position: absolute;
    left: -100px;
    overflow: hidden;
    transition: height 0.4s linear, padding 0.4s linear;
    width: 414px;
    z-index: -1;
    height: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    box-sizing: border-box;

    .user-profile-slideout__link {
        @extend .__default-font;
        color: white;
        text-decoration: none;
        font-size: 0.92rem;
        font-weight: normal;
        padding-top: 9px;
        padding-bottom: 9px;

        &:hover {
            color: white;
            text-decoration: none;
        }
    }
}

.user-profile-slideout__divider {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    width: 100%;
    border-top: 0.5px solid $darkBlue;
}

.user-profile__slideout--visible {
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.user-profile-slideout__version {
    padding: 9px;
    color: white;
    font-size: 0.92rem;
    font-weight: normal;
    text-align: center;
    width: 100%;
    cursor: default;
}

.user-profile-slideout__row {
    padding-top: 0.5rem;
}

.user-profile-slideout__icon {
    margin-right: 40px;
}