/*#region Template Detail Container components*/
.template-detail-container__toggle {
	cursor: pointer;

	font: {
		size: 0.75em;
		style: italic
	}

	left: 210px;
	position: absolute;
}
/*#endregion Template Detail Container components*/


/*#region Template Fields Container components*/
.template-fields-container {
	display: flex;
	flex-wrap: wrap;
	column-gap: 25px;

	margin: {
		top: 10px;
	}

	padding: {
		bottom: 40px;
	}
}
/*#endregion Template Fields Container components*/