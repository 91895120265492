@import '../../styles/_smcColors.scss';
@import '../../styles/_variables.scss';

.tooltip-description__icon {
    color: $gray5;
}

.tooltip-description__icon--error {
    color: $errorRed;
}

.tooltip-description__tooltip {
    .tooltip-inner {
        background-color: $gray5 !important;
    }

    div.arrow::before {
        border-bottom-color: $gray5 !important;
        border-top-color: $gray5 !important;
        color: $white !important;
    }
}

.tooltip-description__tooltip--error {
    .tooltip-inner {
        background-color: $errorRed !important;
    }

    div.arrow::before {
        border-bottom-color: $errorRed !important;
        border-top-color: $errorRed !important;
        color: $white !important;
    }
}
