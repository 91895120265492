@import "styles/_app.scss";
@import "styles/_smcColors.scss";

.category-editor__button-container {
  @extend .__button-container;
}

.category-list__save-new-category {
  margin-left: 1.25rem;

  svg {
    margin-right: 0.625rem;
  }
}

.category-editor__name-label {
  padding-bottom: 10px;
}

.category-editor__permissions {
    padding: 10px 0;

    &-label {
        @extend .__default-font-size-small;

        input {
            margin-right: 10px;
        }
    }

    &-users-card {
        margin: {
            top: 0.5rem;
            right: 0.5rem;
            bottom: 0.5rem;
            left: 0.5rem;
        }

        padding: 0.5rem;

        border: {
            color: #d8d8d8;
            radius: 3px;
            style: solid;
            width: 0.5px;
        }

        box-shadow: 0px 2px 4px rgba($boxShadowColor, 0.16);
    }

    &-users-card-buttons {
        @extend .__button-container;
    }

    &-save {
        margin-left: 1.25rem;
    }

    &-user-tab {
        &:hover {
            @extend .__card--hover;
        }

        @extend .__card-box-shadow;
        border-color: #d8d8d8;
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        padding: 0.5rem;
        height: fit-content;
        margin: 0.5rem;
        display: flex;
        justify-content: space-between;

        &-icons {
            cursor: pointer;
        }
    }
}
