.search-box-container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    border: 1px solid black;
    border-radius: 2px;
    width: 250px;

    input{
        border:none;
        &:focus{
            outline: none;
        }
    }
}

.search-box-button-container {
    justify-content: right;
}

.search-box-button {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 5px;
    color: rgb(26, 26, 26);
}
