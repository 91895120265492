.report-card {
  box-shadow: 2px 2px 2px rgb(0 0 0 / 16%);
  display: flex;
  flex: 1;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    height: 100%;
  }

  .card-title {
    font-weight: 700;
    font-size: 1.1rem;
    padding: 1rem 0.7rem 0 0.7rem;
  }
  .card-body {
    padding: 0;
    height: 100%;
    overflow: auto;
  }
}
