@import '../../../styles/_app.scss';
@import 'CostCenterTab.scss';

.cost-center-editor {
    @extend .__cost-center-card;
}

.cost-center-editor__line {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cost-center-editor__input--half-length {
    width: 12rem;
}

.cost-center-editor__buttons {
    @extend .__button-container;
}