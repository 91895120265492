@import "styles/_app.scss";

.category-card {
    @extend .__card;
    position: relative;

    .nav-link {
        color: black;
        cursor: pointer;

        &.active {
            @extend .__card--active;
        }
    }

    .context-menu {
        cursor: pointer;
    }

    span + svg {
        margin: {
            left: 0.625rem;
        }
    }

    &:hover {
        @extend .__card--hover;
    }
}

.category-card--editing {
    .nav-link.active {
        background-color: white;
    }
}

.category-card__edit-category-input {
    padding: 0.5rem 1rem;
}

.category-card__name {
    overflow: hidden;

    padding: {
        right: 35px;
    }

    text-overflow: ellipsis;
    white-space: nowrap;
}

.category-card__menu-items {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.category-card--disabled {
    @extend .__card--disabled;
}

.category-template-tab-warning__container {
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-50%);
}

.category-template-tab-warning_tooltip {
    .tooltip-inner {
        background-color: $warningYellow;
        color: black;
    }
}

.category-template-tab-warning {
    color: $warningYellow;
}