@import "styles/_app.scss";

.history-log-list__logs {
    @extend .__scrollable-sidebar__content;
    padding: 0.625rem;
}

.history-log-list__no-logs-message {
    text-align: center;
    font-weight: normal;
}
