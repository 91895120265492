@import 'styles/_app.scss';

.template-field-header {
    @extend .__card__title;
}

.template-field-header__cancel {
    @extend .__card__cancel-button;
}

.template-field-form__buttons {
    @extend .__button-container;
}

.template-field__file-input {
    .validation-error-message {
        @extend .__default-font-light;
        @extend .__default-font-size-small;
        @extend .__validation-tool-tip;
    }
}

.template-field-file-input__icon {
    color: $errorRed;
}

.template-detail-container {
    @extend .__section;
}

.template-detail-container__name {
    @extend .__section-name;
}

.template-field-form {
    flex-grow: 1;

    .form-field-container {
        display: inline-block;
        padding: 15px;
    }

    .form-field-container_half-width {
        flex: 0 0 45%;
    }

    .temlate-field-header {
        font-weight: bold;
        font-size: 1.15rem;
    }
}

.data-entry-field-tooltip__textarea {
    padding-top: 10px;
}

.new-field-container {
    border: {
        color: gray;
        radius: 3px;
        style: solid;
        width: 1px;
    }

    height: 117px;

    margin: {
        top: 20px;
    }

    padding: {
        top: 20px;
    }

    position: relative;
    width: 450px;
}

.new-field-button {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.option-associated-label {
    padding-left: 10px;
    padding-bottom: 5px;
    font-weight: normal;
}
