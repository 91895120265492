@import '../../styles/_app.scss';
@import '../../styles/_smcColors.scss';

.toggleSwitch_container{
  label{
    @extend .__default-font-size-small;
    @extend .__default-font-light;
    margin-bottom: 3px;
    display: inherit;
    line-height: 1em;
  }
}

.toggleSwitch--enabled{
  label{
    background: $bluegray;
  }

  label:after {
    background: $white;
  }

  input:checked + label {
    background: $positiveGreen;
  }
}

.toggleSwitch--disabled{
  pointer-events: none;
  label{
    background: $gray50;
  }

  label:after {
    background: $lightGrey;
  }
}

.toggleSwitch {
  height: 14px;

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 28px;
    height: 14px;
    float: right;
    border-radius: 28px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 12px;
    transition: 0.3s;
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}